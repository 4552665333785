<template>
  <v-dialog v-model="modal" persistent :max-width="$vuetify.breakpoint.xs ? 400 : 600" width="100%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        depressed
        :height="$route.name == 'Dashboard' ? 64 : $vuetify.breakpoint.xs ? 48 : 64"
        :width="
          ($route.name == 'Dashboard' && $vuetify.breakpoint.sm) || ($route.name == 'Dashboard' && $vuetify.breakpoint.xs)
            ? '100%'
            : $vuetify.breakpoint.xs
            ? 154
            : 198
        "
        color="primary"
      >
        <v-icon class="me-5" color="white" v-if="$route.name == 'Dashboard'">mdi-plus-box</v-icon> {{ $t('btn.deposit') }}
      </v-btn>
    </template>
    <v-card flat class="pb-sm-10 pt-sm-13 py-6 px-sm-10 px-3">
      <v-btn v-if="step != 4" class="close-btn" @click="modal = false" icon><v-icon color="black">mdi-close</v-icon></v-btn>
      <v-btn v-if="step == 2 || step == 3" class="close-btn gray--text" style="left: 20px; right: inherit" @click="--step" text>
        <v-icon color="gray">mdi-chevron-left</v-icon>{{ $t('btn.back') }}
      </v-btn>
      <v-window v-model="step">
        <v-window-item :value="1">
          <div class="text-center black--text mx-auto" :style="`max-width: ${$vuetify.breakpoint.xs ? 232 : 392}px`">
            <div class="f30 font-weight-black">{{ $t('wallet.depositModal.title') }}</div>
            <div class="gray--text mt-sm-2 mt-6 text-sm-body-1 text-body-2">{{ $t('wallet.depositModal.desc') }}</div>
            <v-text-field
              class="field64 center mt-4"
              :placeholder="$t('input.amountPla')"
              v-model="amount"
              :error-messages="amountErrors"
              dense
              outlined
              color="primary"
              height="64"
            ></v-text-field>
            <v-card
              v-if="!errorNumber.find((item) => item == 'amount__required')"
              flat
              class="black--text success pa-4 mb-sm-10 mb-4 d-flex align-center"
            >
              <img class="me-2" height="24" width="24" src="@/assets/icon/check.svg" alt="processing" />
              <span class="text-body-2">{{ $t('wallet.depositModal.recommend') }} {{ monthPayNumber.monthly_payment }}</span>
            </v-card>
            <v-btn :disabled="!amount" @click="submitDeposit" depressed class="primary" :height="$vuetify.breakpoint.xs ? 48 : 64" block>
              {{ $t('btn.next') }}
            </v-btn>
          </div>
        </v-window-item>
        <v-window-item :value="2">
          <div class="mt-sm-8 mb-sm-6 mb-4 font-weight-bold f18">{{ $t('wallet.depositModal.selectCard') }}</div>
          <v-card
            height="80"
            outlined
            @click="payMethod = i.id"
            class="d-flex align-center mt-4 rounded-lg px-5"
            :class="payMethod == i.id ? 'pay-active' : ''"
            v-for="i in payList"
            :key="i.id"
          >
            <img class="me-5 d-block" :src="getImage(i.card_brand)" :alt="i.card_brand" />
            <div class="font-weight-medium">
              <span class="text-capitalize">{{ i.card_brand }}</span> ******{{ i.card_last_4_digits }}
            </div>
          </v-card>
          <v-card
            height="80"
            outlined
            @click="payMethod = 'card'"
            class="d-flex align-center mt-4 rounded-lg px-5 pay-card"
            :class="payMethod == 'card' ? 'pay-active' : ''"
          >
            <img class="me-5 d-block" :src="getImage('credit-card')" alt="credit-card" />
            <div class="font-weight-medium">{{ $t('newCard') }}</div>
          </v-card>
          <div class="text-sm-center text-end mt-sm-13 mt-8">
            <v-btn
              :disabled="!payMethod"
              @click="submitDeposit"
              depressed
              class="primary"
              :width="$vuetify.breakpoint.xs ? 162 : 384"
              :height="$vuetify.breakpoint.xs ? 48 : 64"
            >
              {{ $t('btn.next') }}
            </v-btn>
          </div>
        </v-window-item>
        <v-window-item :value="3">
          <div class="justify-center gray--text d-flex align-center mt-sm-9">
            <span class="f30 black--text font-weight-black pe-sm-4 pe-2">{{ amount }}</span> SAR
          </div>
          <div class="f18 font-weight-bold mb-5 mt-10">{{ $t('wallet.depositModal.newCard') }}</div>
          <div class="black--text mb-1 text-sm-body-1 text-body-2">{{ $t('input.brandLabel') }}</div>
          <v-select
            class="field56"
            :placeholder="$t('input.brandLabel')"
            v-model="payInfo.brand"
            dense
            outlined
            color="primary"
            height="56"
          ></v-select>
          <div class="black--text mb-1 text-sm-body-1 text-body-2">{{ $t('input.cardHoLabel') }}</div>
          <v-text-field
            class="field56"
            :placeholder="$t('input.cardHoLabel')"
            v-model="payInfo.card_holder"
            dense
            outlined
            color="primary"
            height="56"
          ></v-text-field>
          <div class="black--text mb-1 text-sm-body-1 text-body-2">{{ $t('input.cardNumLabel') }}</div>
          <v-text-field
            class="field56"
            :placeholder="$t('input.cardNumLabel')"
            v-model="payInfo.card_number"
            dense
            outlined
            color="primary"
            height="56"
          ></v-text-field>
          <div class="d-flex">
            <div class="pe-3 width100">
              <div class="black--text mb-1 text-sm-body-1 text-body-2">{{ $t('input.exporationLabel') }}</div>
              <v-text-field
                class="field56"
                :value="payInfo.date ? new Date(payInfo.date).toLocaleString('en-GB', { year: '2-digit', month: 'numeric' }) : null"
                @click="dateModal = true"
                placeholder="MM/YY"
                outlined
                readonly
                dense
                required
                color="primary"
                height="56"
              ></v-text-field>
            </div>
            <div :class="$vuetify.breakpoint.xs ? '' : 'ps-3 width100'">
              <div class="black--text mb-1 text-sm-body-1 text-body-2">{{ $t('input.cvv') }}</div>
              <v-text-field
                class="field56"
                placeholder="***"
                v-model="payInfo.cvv"
                type="password"
                maxLength="3"
                dense
                outlined
                color="primary"
                height="56"
              ></v-text-field>
            </div>
          </div>
          <v-checkbox class="mt-0" v-model="payInfo.checkbox" :label="$t('wallet.depositModal.saveCard')"></v-checkbox>
          <div class="text-sm-center text-end">
            <v-btn
              :disabled="!amount"
              @click="++step"
              depressed
              class="primary"
              :width="$vuetify.breakpoint.xs ? 162 : 384"
              :height="$vuetify.breakpoint.xs ? 48 : 64"
            >
              {{ $t('btn.submit') }}
            </v-btn>
          </div>
        </v-window-item>
        <v-window-item :value="4">
          <v-card flat :min-height="$vuetify.breakpoint.xs ? 210 : 348" class="d-flex align-center justify-center">
            <div class="text-center mb-sm-0 mb-6">
              <img src="@/assets/icon/processing.svg" alt="processing" />
              <div class="f30 black--text font-weight-bold">{{ $t('wallet.procesTitle') }}</div>
              <div class="gray--text mt-2">{{ $t('wallet.procesDesc') }}</div>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="5">
          <v-card flat tile class="text-center mx-auto" max-width="384">
            <img class="mt-sm-12 mt-4" src="@/assets/icon/check.svg" alt="processing" />
            <div class="f30 black--text font-weight-bold mt-sm-9 mt-4 mb-sm-16 mb-2 pb-sm-5 pb-6">{{ $t('wallet.payTitle') }}</div>
            <div class="mx-auto" :style="`max-width: ${$vuetify.breakpoint.xs ? 232 : 384}px`">
              <v-btn @click="modal = false" depressed class="primary" :height="$vuetify.breakpoint.xs ? 48 : 64" block>
                {{ $t('btn.nice') }}
              </v-btn>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item :value="6">
          <v-card flat tile class="text-center mx-auto" max-width="384">
            <img class="mt-sm-12 mt-4" src="@/assets/icon/cancel.svg" alt="error" />
            <div class="f30 black--text font-weight-bold mt-sm-9 mt-4">{{ $t('wallet.failTitle') }}</div>
            <div class="gray--text mt-2 mb-sm-13 mb-6">{{ $t('wallet.failDesc') }}</div>
            <div class="mx-auto" :style="`max-width: ${$vuetify.breakpoint.xs ? 232 : 384}px`">
              <v-btn @click="modal = false" depressed class="primary" :height="$vuetify.breakpoint.xs ? 48 : 64" block>
                {{ $t('btn.nice') }}
              </v-btn>
            </div>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card>
    <v-dialog v-model="dateModal" persistent width="290px">
      <v-date-picker v-if="dateModal" value="mm-YY" no-title v-model="payInfo.date" type="month" color="primary" @input="dateModal = false">
        <!-- <v-spacer></v-spacer>
        <v-btn text color="error" @click="dateModal = false">{{ $t('btn.cancel') }}</v-btn>
        <v-btn text color="primary" @click="dateModal = false">{{ $t('btn.submit') }}</v-btn> -->
      </v-date-picker>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dateModal: false,
      modal: false,
      step: 1,
      amount: '',
      payMethod: '',
      payId: '',
      payInfo: {
        checkbox: true,
      },
      // payList: [
      //   { icon: 'visa', title: 'Visa ******1234' },
      //   { icon: 'master', title: 'Mastercard ******1234' },
      //   { icon: 'mada', title: 'Mada ******1234' },
      //   { icon: 'credit-card', title: 'New card' },
      // ],
      errorNumber: [],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getPaymentCardList');
    this.$store.dispatch('getMonthPayNumber').catch((e) => {
      this.errorNumber = e.response.data.error;
    });
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
    async submitDeposit() {
      const data = new Object();
      data.amount = this.amount * 100;
      // if (this.payMethod != 'card') {
      //   data.payment_card = this.payMethod;
      //   data.save_card = this.payInfo.checkbox;
      // }
      await this.$store
        // .dispatch('submitWalletDeposit', data)
        .dispatch('submitWalletDepositFake', data)
        .then((res) => {
          this.step = 5;
          // if (this.step == 2 && this.payMethod != 'card') {
          //   this.step = 4;
          //   this.payId = res.data.payment;
          //   this.$store.dispatch('getPaymentInfo', this.payId);
          // } else {
          //   ++this.step;
          // }
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.error.find((item) => item == 'amount__required') || this.error.find((item) => item == 'amount__invalid')
            ? (this.step = 1)
            : '';
          this.error.find((i) => i == 'questionnaire__not_provided') ? this.$router.push(`/question/start?page=${this.$route.path}`) : '';
        });
    },
  },
  watch: {
    modal() {
      this.payMethod = '';
      this.amount = '';
      this.step = 1;
      this.payInfo = {
        checkbox: true,
      };
    },
    paymentInfo(val) {
      if (val.status == 'created') {
        setTimeout(() => {
          this.$store.dispatch('getPaymentInfo', this.payId);
        }, 5000);
      } else if (val.status == 'paid') {
        this.step = 5;
      } else if (val.status == 'failed') {
        this.step = 6;
      }
    },
  },
  computed: {
    payList() {
      return this.$store.getters.paymentCardList;
    },
    paymentInfo() {
      return this.$store.getters.paymentInfo;
    },
    monthPayNumber() {
      return this.$store.getters.monthPayNumber;
    },
    amountErrors() {
      const errors = [];
      this.error.find((item) => item == 'amount__required') && errors.push(this.$t('inputError.amountRequired'));
      this.error.find((item) => item == 'amount__invalid') && errors.push(this.$t('inputError.amountInvalid'));
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setWallet');
    this.$store.dispatch('setPaymentCard');
  },
};
</script>

<style lang="scss">
#app .v-sheet--outlined {
  border-color: #ebeff4 !important;
}
#app .pay-card {
  border: 1px dashed var(--v-gray-base) !important;
}
#app .pay-active {
  border: 1px solid var(--v-primary-base) !important;
  background: var(--v-primary-lighten5);
}
</style>
